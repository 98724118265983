@tailwind components;
@tailwind base;
@tailwind utilities;
@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);

@layer base {
  :root {
    --color-bg-01: #111319;
    --color-bg-02: #0b0b0b;
    --color-bg-03: #292d41;
    --color-button-gray: #222531;
    --color-button-white: #ffffff;
    --color-text-white: #ffffff;
    --color-text-yellow: #fddc3f;
    --color-text-red: #ff3434;
    --color-text-lightblue: #18c6d1;
    --color-text-blue: #1e6dff;
    --color-text-lightgray: #a7accb;
    --color-text-gray: #4d536f;
    --color-text-darkgray: #1a1a1a;
    --color-divider: #191c24;
  }
}

* {
  font-family: "Spoqa Han Sans Neo", "sans-serif";
}

*::-webkit-scrollbar {
  @apply bg-bg01 w-[12px];
}

*::-webkit-scrollbar-thumb {
  @apply bg-buttonGray rounded-[16px];
}

.swiper-pagination-bullet {
  background: #022877 !important;
  width: 5px !important;
  height: 5px !important;
}

.swiper-pagination-bullet-active {
  background: #8bb0ff !important;
}
/* You can add global styles to this file, and also import other style files */
